'use client';

import CookieConsent from 'react-cookie-consent';
import { Link } from '@nextui-org/link';
import clsx from 'clsx';
import { link as linkStyles } from '@nextui-org/theme';

const CookieBanner = () => {
  return (
    <CookieConsent debug={false}>
      This website uses cookies to enhance the user experience.{' '}
      <Link
        className={clsx(
          linkStyles({ color: 'warning', underline: 'hover' }),
          'data-[active=true]:text-primary data-[active=true]:font-medium'
        )}
        href="/terms"
      >
        Privacy Policy
      </Link>
    </CookieConsent>
  );
};

export default CookieBanner;
