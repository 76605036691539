'use client';

import {
  Button,
  Image,
  Link,
  Listbox,
  ListboxItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  cn,
} from '@nextui-org/react';

import { signIn, signOut, useSession } from 'next-auth/react';
import { FaSignInAlt, FaSignOutAlt } from 'react-icons/fa';

function AuthButton() {
  const { data: session } = useSession();

  const doSignOut = async () => {
    await signOut();
  };

  const doSignIn = async () => {
    await signIn('github');
  };

  if (session?.user?.image) {
    const userImg = session?.user?.image;
    if (!userImg)
      return (
        <Tooltip content="Sign out">
          <Button
            isIconOnly
            radius="sm"
            variant="bordered"
            startContent={<FaSignOutAlt />}
            onClick={doSignOut}
          />
        </Tooltip>
      );

    const iconClasses =
      'text-xl text-default-500 pointer-events-none flex-shrink-0';
    return (
      <Popover placement="bottom" showArrow offset={10}>
        <PopoverTrigger>
          <Image
            src={userImg}
            className="cursor-pointer"
            loading="lazy"
            width={28}
            height={28}
            radius="lg"
            alt="User image"
          />
        </PopoverTrigger>
        <PopoverContent className="w-[240px]">
          {(titleProps) => (
            <div className="w-full px-1 py-2">
              <p
                className="px-1 text-small font-bold text-foreground"
                {...titleProps}
              >
                {session?.user?.name}
              </p>
              <p className="px-1 text-small text-slate-500 dark:text-slate-300">
                {session?.user?.email}
              </p>
              <div className="mt-2 flex w-full flex-col gap-2">
                <Listbox
                  variant="faded"
                  aria-label="User options"
                  className="px-0"
                  onAction={(key) => {
                    switch (key) {
                      case 'signout':
                        doSignOut();
                    }
                  }}
                >
                  <ListboxItem key="signout" startContent={<FaSignOutAlt />}>
                    Sign out
                  </ListboxItem>
                </Listbox>
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    );
  }
  // return (
  //   <Tooltip content="Sign in via Github">
  //     <Button
  //       variant="faded"
  //       startContent={<FaSignInAlt />}
  //       onClick={() => signIn('github')}
  //     >
  //       {/* Sign in */}
  //     </Button>
  //   </Tooltip>
  // );
  return (
    <Tooltip content="Sign in via Github">
      <Link
        className="cursor-pointer !text-default-500"
        aria-label="Sign in"
        onClick={doSignIn}
      >
        <FaSignInAlt size={22} />
      </Link>
    </Tooltip>
  );
}

export default function Authenticator() {
  return <AuthButton />;
}
