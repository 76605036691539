import * as React from 'react';
import { IconSvgProps } from '@/types';

export const Logo: React.FC<IconSvgProps> = ({
  width = 150,
  height = 40,
  ...props
}) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 1387.15 362.521"
    width={width}
    {...props}
  >
    <path
      d="M94.9832 268.37C108.889 268.37 117.796 259.776 117.796 244.62L117.796 170.245C117.796 150.401 125.139 138.683 145.921 138.37C154.827 138.214 161.389 136.183 165.764 132.433C170.139 128.683 172.327 123.214 172.327 116.026C172.327 108.214 170.139 102.589 165.764 99.1513C162.483 96.3388 157.639 94.9325 151.546 94.9325C134.827 94.9325 121.077 107.276 116.702 128.058L115.764 128.058L115.764 117.12C115.764 102.589 107.014 94.1513 94.202 94.1513C81.077 94.1513 72.1707 102.589 72.1707 117.12L72.1707 244.62C72.1707 259.776 81.2332 268.37 94.9832 268.37Z"
      fill="currentColor"
    />
    <path
      d="M260.139 268.604C292.483 268.604 318.421 256.417 329.514 235.792C331.546 232.042 332.639 228.292 332.639 224.698C332.639 214.229 324.671 207.979 314.827 207.979C308.889 207.979 304.827 209.698 299.983 214.698C287.639 229.229 276.702 234.854 259.983 234.854C236.858 234.854 221.858 218.604 221.858 193.761L221.858 191.729L317.483 191.729C330.139 191.729 337.327 184.542 337.327 171.886C337.327 126.417 305.452 93.9169 257.952 93.9169C208.421 93.9169 177.014 128.136 177.014 182.042C177.014 236.261 207.796 268.604 260.139 268.604ZM222.327 163.761C223.889 142.354 238.421 127.667 258.421 127.667C278.421 127.667 292.483 141.886 293.421 163.761L222.327 163.761Z"
      fill="currentColor"
    />
    <path
      d="M428.733 268.37C445.296 268.37 454.827 261.183 460.608 245.089L504.202 127.276C505.608 123.37 506.702 118.683 506.702 114.464C506.702 102.901 497.171 94.1513 484.514 94.1513C473.889 94.1513 466.233 99.9325 463.108 111.964L429.202 224.776L428.264 224.776L394.358 112.433C390.921 100.089 383.577 94.1513 372.483 94.1513C359.514 94.1513 349.671 102.276 349.671 114.62C349.671 119.308 350.764 124.151 352.171 128.214L395.764 245.089C401.858 261.339 411.233 268.37 428.733 268.37Z"
      fill="currentColor"
    />
    <path
      d="M586.858 268.214C612.171 268.214 628.733 256.026 637.014 234.933L637.952 234.933L637.952 245.245C637.952 260.401 646.858 268.37 659.983 268.37C673.108 268.37 682.014 260.401 682.014 245.245L682.014 118.058C682.014 102.433 672.639 94.1513 659.202 94.1513C645.921 94.1513 636.389 102.433 636.389 118.058L636.389 193.526C636.389 215.714 623.733 230.245 603.421 230.245C583.264 230.245 572.171 218.37 572.171 196.026L572.171 118.058C572.171 102.433 562.796 94.1513 549.358 94.1513C536.077 94.1513 526.546 102.433 526.546 118.058L526.546 205.558C526.546 244.62 550.608 268.214 586.858 268.214Z"
      fill="currentColor"
    />
    <path
      d="M817.171 297.823C880.764 297.823 917.171 259.229 917.171 201.886L917.171 198.448C917.171 182.979 908.421 173.917 893.577 173.917L838.421 173.917C827.014 173.917 819.514 180.323 819.514 191.104C819.514 201.886 827.171 208.292 838.421 208.292L871.233 208.292L871.077 211.886C870.139 239.854 848.733 258.761 817.952 258.761C780.452 258.761 756.702 228.917 756.702 180.011C756.702 132.042 779.046 103.761 816.233 103.761C838.421 103.761 854.827 113.604 865.921 133.761C871.702 143.292 878.733 147.511 888.733 147.511C901.077 147.511 909.358 139.229 909.358 127.198C909.358 122.667 908.577 118.136 905.921 112.667C893.264 85.0107 859.358 64.6982 816.077 64.6982C749.827 64.6982 708.577 108.448 708.577 180.636C708.577 253.917 749.827 297.823 817.171 297.823Z"
      opacity="0.5"
      fill="currentColor"
    />
    <path
      d="M971.858 295.479C986.702 295.479 995.452 286.417 995.452 270.948L995.452 221.417L1037.64 221.417C1085.14 221.417 1118.11 190.479 1118.11 144.229C1118.11 97.8232 1086.23 67.0419 1039.98 67.0419L971.858 67.0419C957.014 67.0419 948.264 76.1044 948.264 91.5732L948.264 270.948C948.264 286.417 957.014 295.479 971.858 295.479ZM995.452 184.542L995.452 104.386L1027.48 104.386C1054.51 104.386 1070.14 118.761 1070.14 144.386C1070.14 170.167 1054.36 184.542 1027.33 184.542L995.452 184.542Z"
      opacity="0.5"
      fill="currentColor"
    />
    <path
      d="M1223.42 295.479C1238.26 295.479 1247.01 286.417 1247.01 270.948L1247.01 106.104L1293.73 106.104C1306.39 106.104 1314.98 98.7607 1314.98 86.5732C1314.98 74.3857 1306.55 67.0419 1293.73 67.0419L1153.26 67.0419C1140.3 67.0419 1131.86 74.3857 1131.86 86.5732C1131.86 98.7607 1140.45 106.104 1153.26 106.104L1199.83 106.104L1199.83 270.948C1199.83 286.417 1208.58 295.479 1223.42 295.479Z"
      opacity="0.5"
      fill="currentColor"
    />
  </svg>
);
